import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "../store";
import AuthService from './AuthService';
Vue.prototype.$auth = AuthService;
Vue.use(VueRouter)



const rolesPermitidos = {
  homeView: ["ADMIN", "STORER"],
  comisiones: ["ADMIN", "STORER"],
  NewOrder: ["ADMIN", "SALES AGENT"]
};

const routes = [
  {
    path: '/login',
    name: 'loginview',
    component: () => import('../components/LoginView.vue'),
    beforeEnter: (to, from, next) => {
       if(store.state.token === ""){
        next()
       }else{
         next('/');
       }
    }
  },
  {
    path: '/',
    name: 'homeView',
    meta: { requireAuth: true, roles: rolesPermitidos.homeView  },
    component: HomeView
  },

  {
    path: '/comisiones',
    name: 'comisiones',
    meta: { requireAuth: true, roles: ["ADMIN"] },
    component: () => import('../components/CalcComissions.vue')
  },
  {
    path: '/newcalccomisions',
    name: 'newCalcComisions',
    meta: { requireAuth: true, roles: ["ADMIN"] },
    component: () => import('../components/NewCalcComisions.vue')
  },
  {
    path: '/comisionessaved',
    name: 'comisionessaved',
    meta: { requireAuth: true, roles: ["ADMIN"] },
    component: () => import('../components/ComisionesView.vue')
  },

  {
    path: '/newOrder',
    name: 'NewOrder',
    meta: { requireAuth: true, roles: ["ADMIN", "SALES AGENT", "STORER"]},
    component: () => import('../components/NewOrder.vue'),
    beforeEnter: (to, from, next) => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
      if (isMobile) {
        next('/newOrderMovil');
      } else {
        next();
      }
    },
  },
  {
    path: '/newOrderMovil',
    name: 'newOrderMovil',
    meta: { requireAuth: true, roles: ["ADMIN", "SALES AGENT", "STORER"]  },
    component: () => import('../components/NewOrderMovil.vue')

  },  

  {
    path: '/chargeordercsv',
    name: 'chargeordercsv',
    meta: { requireAuth: true, requireadmin: true, requiresalesagent:true  },
    component: () => import('../components/ChargeOrderCSV.vue')

  },  

  {
    path: '/recibosdigitales',
    name: 'recibosdigitales',
    meta: { requireAuth: true, roles: ["ADMIN", "SALES AGENT"] },
    component: () => import('../components/allDigitalTickets.vue')

  }, 

  {
    path: '/recibodigital',
    name: 'recibodigital',
    meta: { requireAuth: true, roles: ["ADMIN", "SALES AGENT"] },
    component: () => import('../components/DigitalTickets.vue')

  },  


  {
    path: '/expenses',
    name: 'expenses',
    meta: { requireAuth: true, roles: ["ADMIN", "SALES AGENT", "STORER"] },
    component: () => import('../components/ExpensesView.vue')
  },
  {
    path: '/customers',
    name: 'customers',
    meta: { requireAuth: true,  roles: ["ADMIN", "SALES AGENT"] },
    component: () => import('../components/CustomersView.vue')
  },
  {
    path: '/pendingOrders',
    name: 'pendingorders',
    meta: { requireAuth: true, roles: ["ADMIN",  "STORER"]  },
    component: () => import('../views/PendingOrders.vue')
  },
  {
    path: '/pendingOrdersDelivery',
    name: 'pendingordersdelivery',
    meta: { requireAuth: true, roles: ["ADMIN", "SALES AGENT", "STORER"] },
    component: () => import('../components/PendingOrdersDelivery.vue')
  },
  {
    path: '/detailsOrder/:id',
    name: 'detailsorder',
    meta: { requireAuth: true, roles: ["ADMIN", "SALES AGENT", "STORER"] },
    component: () => import('../components/DetailsOrder.vue')
  },
  {
    path: '/almacen',
    name: 'almacen',
    meta: { requireAuth: true, roles: ["ADMIN", "STORER"] },
    component: () => import('../views/StorageView.vue')
  },
  {
    path: '/entrada',
    name: 'entrada',
    meta: { requireAuth: true, roles: ["ADMIN"] },
    component: () => import('../components/EntradaMercancia.vue')
  },
  {
    path: '/salida',
    name: 'salida',
    meta: { requireAuth: true, roles: ["ADMIN",] },
    component: () => import('../components/SalidaMercancia.vue')
  },
  {
    path: '/historymoves',
    name: 'historymoves',
    meta: { requireAuth: true, roles: ["ADMIN", "STORER"]  },
    component: () => import('../components/HistoryMoves.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: { requireAuth: true, roles: ["ADMIN"]  },
    component: () => import('../components/UsersView.vue')
  },
  {
    path: '/products',
    name: 'products',
    meta: { requireAuth: true, roles: ["ADMIN"]  },
    component: () => import('../components/ProductView.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    meta: { requireAuth: true, roles: ["ADMIN", "SALES AGENT", "STORER"] },
    component: () => import('../components/OrdersView.vue')
  },
  {
    path: '/balancesandpayments',
    name: 'balancesAndPayments',
    meta: { requireAuth: true, roles: ["ADMIN"] },
    component: () => import('../components/BalancesAndPayments.vue')
  },
  {
    path: '/notasdecredito',
    name: 'notasdecredito',
    meta: { requireAuth: true, roles: ["ADMIN"]  },
    component: () => import('../components/NotasdeCredito.vue')
  },
  {
    path: '/customerbalancecredit/:id',
    name: 'customerbalancecredit',
    meta: { requireAuth: true, roles: ["ADMIN", "SALES AGENT"] },
    component: () => import('../components/BalanceCustomer.vue')
  },


  {
    path: '/settings',
    name: 'settings',
    meta: { requireAuth: true, roles: ["ADMIN"] },
    component: () => import('../views/SettingsView.vue')
  },
  {
    path: '/configfacturacion',
    name: 'configfacturacion',
    meta: { requireAuth: true, roles: ["ADMIN"] },
    component: () => import('../components/ConfigFacturacionView.vue')
  },
  {
    path: '/generalsettings',
    name: 'generalsettings',
    meta: { requireAuth: true, roles: ["ADMIN"]},
    component: () => import('../components/GeneralSettingsView.vue')
  },
  {
    path: '/entradaxml',
    name: 'entradaxml',
    meta: { requireAuth: true, roles: ["ADMIN"]  },
    component: () => import(/* webpackChunkName: "about" */ '../components/EntradaProductosXML.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    meta: { requireAuth: true, roles: ["ADMIN"] },
    component: () => import(/* webpackChunkName: "about" */ '../views/reportsView.vue')
  },
  {
    path: '/reportgeneralsales',
    name: 'reportgeneralsales',
    meta: { requireAuth: true, roles: ["ADMIN"]},
    component: () => import(/* webpackChunkName: "about" */ '../components/ReportGeneralSales.vue')
  },
  {
    path: '/reportgeneralpayments',
    name: 'reportgeneralpayments',
    meta: { requireAuth: true, roles: ["ADMIN"]},
    component: () => import(/* webpackChunkName: "about" */ '../components/ReportGeneralPayments.vue')
  },
  {
    path: '/rmensual',
    name: 'rmensual',
    meta: { requireAuth: true, roles: ["ADMIN"]},
    component: () => import(/* webpackChunkName: "about" */ '../components/ReporteMensual.vue')
  },
  {
    path: '/customerpanel',
    name: 'customerpanel',
    meta: { requireAuth: true, roles: ["CUSTOMER"] },
    component: () => import(/* webpackChunkName: "about" */ '../views/CustomerPanelView.vue')
  },
  {
    path: '/accountstatecustomer',
    name: 'accountstatecustomer',
    meta: { requireAuth: true, roles: ["CUSTOMER"] },
    component: () => import(/* webpackChunkName: "about" */ '../components/AccountStateCustomer.vue')
  },
  {
    path: '/customerorders',
    name: 'customerorders',
    meta: { requireAuth: true, roles: ["CUSTOMER"]},
    component: () => import(/* webpackChunkName: "about" */ '../components/CustomerOrders.vue')
  },
  {
    path: '/paymentorderspei/:id',
    name: 'paymentspei',
    meta: { requireAuth: true, roles: ["CUSTOMER"] },
    component: () => import('../components/PaymentInvoiceSpei.vue')
  },
  {
    path: '/paymentorderspeiopenpay/:id',
    name: 'paymentorderspeiopenpay',
    meta: { requireAuth: true, roles: ["CUSTOMER"] },
    component: () => import('../components/PaymentInvoiceSpeiOpenPay.vue')
  },
  {
    path: '/paymentrefspei/:id',
    name: 'paymentrefspei',
    meta: { requireAuth: true, roles: ["CUSTOMER"]  },
    component: () => import('../components/paymentRefSpei.vue')
  },

  {
    path: '/locationsstorage',
    name: 'locationsstorage',
    meta: { requireAuth: true, roles: ["ADMIN"]  },
    component: () => import('../components/LocationsStorageView.vue')
  },
  {
    path: '/apl',
    name: 'apl',
    meta: { requireAuth: true, roles: ["ADMIN", "STORER"]  },
    component: () => import('../components/AsignProductLocation.vue')
  },
  {
    path: '/inventariociclico',
    name: 'inventariociclico',
    meta: { requireAuth: true, roles: ["ADMIN", "STORER"]   },
    component: () => import('../components/GenerateReportInvCiclico.vue')
  },
  {
    path: '/productubicacion',
    name: 'productubicacion',
    meta: { requireAuth: true, roles: ["ADMIN", "STORER"]   },
    component: () => import('../components/ProductosUbicaciones.vue')
  },
  {
    path: '/historycustomer',
    name: 'historycustomer',
    meta: { requireAuth: true, roles: ["ADMIN"]   },
    component: () => import('../components/HistoryCustomerPayments.vue')
  },
  {
    path: '/checkproduct/:order',
    name: 'checkproduct',
    meta: { requireAuth: true, roles: ["ADMIN", "STORER", "CUSTOMER"]   },
    component: () => import('../components/CheckProduct.vue')
  },
  {
    path: '/garantias',
    name: 'garantias',
    meta: { requireAuth: true, roles: ["CUSTOMER", "ADMIN"]  },
    component: () => import(/* webpackChunkName: "about" */ '../components/GarantiasView.vue')
  },
  {
    path: '/invoiceswatermaker/:id',
    name: 'invoiceswatermaker',
    meta: { requireAuth: true, roles: ["ADMIN", "STORER"] },
    component: () => import(/* webpackChunkName: "about" */ '../components/AddWaterMakerInvoice.vue')
  },
  {
    path: '/invoicesTicket/:id/:data',
    name: 'invoicesTicket',
    meta: { requireAuth: true, roles: ["ADMIN", "STORER"] },
    component: () => import(/* webpackChunkName: "about" */ '../components/PrinterTicketOrder.vue')
  },
  {
    path: '/newordercustomer',
    name: 'newordercustomer',
    meta: { requireAuth: true, roles: ["CUSTOMER"]  },
    component: () => import(/* webpackChunkName: "about" */ '../components/NewOrderCustomer.vue')
  },
  {
    path: '/ordersAsign',
    name: 'ordersAsign',
    meta: { requireAuth: true},
    component: () => import(/* webpackChunkName: "about" */ '../components/OrdersAsignDelivery.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },

  {
    path: '/privacynotice',
    name: 'privacynotice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/PrivacyNotice.vue')
  },
  {
    path: '/sellooriginal',
    name: 'sellooriginal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/SelloOriginalFactura.vue')
  },
  {
    path: '/customergeolocation',
    name: 'customergeolocation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/CustomerGeolocations.vue')
  },
  {
    path: '*',
    component: () => import('../components/LoginView.vue'),
    // ... otras configuraciones de la ruta no encontrada
  },

]




const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  
});


router.beforeEach((to, from, next) => {
  const isAuthenticated = Vue.prototype.$auth.isAuthenticated();
 

  // Verificar autenticación
  if (to.meta.requireAuth && !isAuthenticated) {
    next({ name: 'loginview' });
  } else if (to.meta.inecesaria && isAuthenticated) {
    next({ name: 'loginview' });
  } else {
    next();
  }

  var userRole = store.state.usuarioDB.data.role;


  // Verificar roles permitidos
  const rolesPermitidos = to.meta.roles;

  if (rolesPermitidos && !Vue.prototype.$auth.hasRole(userRole, rolesPermitidos)) {
    // Redirigir a una página adecuada en caso de roles no permitidos
    next({ name: 'homeView' }); // Puedes personalizar esto según tus necesidades
  } else {
    next()
  
  }



});


export default router
